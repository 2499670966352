@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

:root {
    --main_bg_color: #F7F7F7;
    --secondary_bg_color: #ffffff;
    --border-radius: 2rem;
    --hover_color: #0FF5CE;
    --inner_box_shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
    --input_box_shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    --outer_box_shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

body {
    overflow: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

input[type="date"] {
    width: 100%;
}

body {
    background-color: var(--main_bg_color);
}

a {
    text-decoration: none;
}

.srv-validation-message {
    font-size: 12px;
    text-align: left;
    margin: 2px 0px 0px 5px;
    color: red;
}

.view_loader {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
}

.loader {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid;
    border-top-color: #0FF5CE;
    border-right-color: #0FF5CE;
    border-bottom-color: #0FF5CE;
    border-left-color: #fff;
    animation: loader_anim 0.6s linear infinite;
}

@keyframes loader_anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.data_not_available {
    padding-left: 35%;
    padding-top: 3%;
}

/************Client Sign in Start************/
.sign_in_section {
    width: 100vw;
    height: 100vh;
    background-color: var(--main_bg_color);
    display: grid;
    place-items: center;
}

.sign_in_form_main {
    width: 70%;
    margin: auto;
    display: flex;
    align-items: center;
    background-color: var(--secondary_bg_color);
    border-radius: var(--border-radius);
    box-shadow: var(--outer_box_shadow);
    padding: 4% 2%;
}

.sign_in_form_main .company_details,
.sign_in_form_main .sign_in_form {
    width: 50%;
    text-align: center;
}

.company_details h1 {
    margin-bottom: 1rem;
}

.company_details p {
    margin-bottom: 0.4rem;
}

.sign_in_form_main .sign_in_form {
    background-color: var(--main_bg_color);
    border-radius: var(--border-radius);
    box-shadow: var(--inner_box_shadow);
    padding: 5%;
}

.sign_in_form h2 {
    margin-bottom: 2rem;
}

.sign_in_form .input_field {
    margin-bottom: 2rem;
}

.sign_in_form .input_field input {
    width: 100%;
    background-color: var(--secondary_bg_color);
    border-radius: var(--border-radius);
    box-shadow: var(--input_box_shadow);
    padding: 1rem;
    border: none;
    outline: none;
}

.sign_in_form .input_field input::placeholder {
    font-weight: 700;
    color: #000;
}

.sign_in_form button {
    border: 1px solid var(--hover_color);
    outline: none;
    padding: 0.6rem 2rem;
    background-color: var(--secondary_bg_color);
    border-radius: var(--border-radius);
    box-shadow: var(--input_box_shadow);
    font-weight: bold;
    cursor: pointer;
}

.sign_in_form button:hover {
    background-color: var(--hover_color);
    color: var(--secondary_bg_color);
}

/************Client Sign in End************/

/************Client Dashboard Start************/
.dashboard_section {
    width: 100%;
    height: 100vh;
    background-color: var(--main_bg_color);
}

/*-------CSS for Top Header-----*/
.header_main {
    width: 100%;
    padding: 5px 15px;
    background-color: var(--secondary_bg_color);
}

.header_main .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: var(--inner_box_shadow);
    padding: 5px 15px;
}

.header_main .tabs_main,
.table_item .button_group {
    display: flex;
    align-items: center;
}

.table_item .button_group {
    justify-content: center;
}

.header_main .tabs_main .tab,
.table_item .button_group button {
    padding: 5px 15px;
    border-radius: 20px;
    background-color: var(--secondary_bg_color);
    border: 1px solid var(--hover_color);
    cursor: pointer;
    margin-right: 10px;
    transition: 0.4s;
    color: #000;
}

.table_item .button_group button {
    padding: 1px 8px;
    margin: 0 5px;
}

.header_main .tabs_main .tab:hover,
.table_item .button_group button:hover {
    background-color: var(--hover_color);
    color: var(--secondary_bg_color);
}

.header_main .tabs_main .active .tab {
    background-color: var(--hover_color);
    color: var(--secondary_bg_color);
}

/*-----CSS for Generate bill tab----*/
.generate_bill_form_main,
.account_form_main,
.view_bill_form_main,
.customer_personal_detail {
    padding: 1% 5%;
    background-color: var(--secondary_bg_color);
    margin: 10px 0;
    border-radius: 15px;
    box-shadow: var(--outer_box_shadow);
}

.customer_personal_detail {
    border-radius: 0;
    display: flex;
    align-items: center;
    gap: 15px;
}

.customer_personal_detail span {
    font-size: 14px;
}

.customer_personal_detail span b {
    font-weight: 600;
}

.view_bill_form_main {
    padding: 0.5% 5%;
    position: relative;
}

.input_row {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 8px;
}

.view_bill_form_main .input_row {
    margin: 0;
}

.input_row .input_box {
    width: calc(100% / 5);
    text-align: center;
}

.input_row .input_box input,
.table_section input,
.input_row .input_box select {
    width: 100%;
    padding: 5px 10px;
    border-radius: var(--border-radius);
    border: none;
    box-shadow: rgba(188, 188, 190, 0.25) 0px 30px 60px -12px inset, rgba(150, 149, 149, 0.3) 0px 18px 36px -18px inset;
}

.input_row .search_input {
    margin-left: 2%;
}

.input_row .search_input input {
    width: 250px;
}

.input_row .input_box input:focus,
.table_section input:focus {
    outline: none;
}

.button {
    padding: 5px 20px;
    background-color: var(--secondary_bg_color);
    box-shadow: var(--input_box_shadow);
    border: none;
    border-radius: var(--border-radius);
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
}

.table_section,
.account_table_section,
.view_bill_table_section {
    width: 100%;
    height: 51vh;
    overflow: auto;
    position: relative;
}

.generate_bill_table {
    height: 58vh;
}

.account_table_section {
    height: 74vh;
}

.view_bill_table_section {
    height: 74vh;
}

.table_section .table_wrapper {
    height: 45vh;
    min-width: 1650px;
}

.view_bill_table_section .table_wrapper {
    height: 71vh;
}

.account_table_section .table_wrapper {
    /* height: 66vh; */
    min-width: 1650px;
}

.table_wrapper .table_header,
.table_wrapper .table_data {
    display: flex;
    align-items: center;
    background-color: var(--secondary_bg_color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    padding: 5px 10px;
    box-shadow: rgba(139, 139, 139, 0.19) 0px 10px 20px, rgba(148, 148, 148, 0.23) 0px 6px 6px;
}

.table_wrapper .table_header {
    position: sticky;
    top: 0;
}

.table_wrapper .table_data {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin: 5px 0;
}

.table_wrapper .table_item {
    width: 250px;
    text-align: center;
    border-right: 1px solid #000;
    padding: 0 2px;
}

.table_wrapper .table_item span {
    font-size: 13px;
}

.table_wrapper .table_data .table_item span {
    font-size: 12px;
    color: blue;
}

.table_wrapper .table_item:last-child {
    border-right: none;
}

.table_wrapper .table_item span svg {
    width: 12px;
    cursor: pointer;
    fill: red;
}

.table_section .total_amount_section {
    width: 100%;
    position: fixed;
    bottom: 0;
    background-color: var(--secondary_bg_color);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding: 5px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table_section .total_amount_section .amount,
.table_section .total_amount_section .button_group {
    display: flex;
    align-items: center;
}

.table_section .total_amount_section .amount input {
    margin-left: 20px;
}

.table_section .total_amount_section .button_group button {
    margin: 0 10px;
}

/*-------------CSS For View Bill Tab-----------*/
.account_form_main .button_group {
    text-align: center;
}

.bill_detail {
    position: relative;
    height: 92vh;
}

.bill_detail::before {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4vh;
    background-color: var(--secondary_bg_color);
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
}

.bill_detail .bill_wrapper {
    width: 100%;
    margin-top: 10px;
}

.bill_wrapper .table_header {
    background-color: var(--secondary_bg_color);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
    padding: 8px 10px;
    box-shadow: rgba(139, 139, 139, 0.19) 0px 10px 20px, rgba(148, 148, 148, 0.23) 0px 6px 6px;
    text-align: right;
}

.bill_wrapper .table_header .button_group button {
    margin: 0 5px;
}

.bill_table_section {
    width: 80%;
    margin: auto;
    height: 80vh;
    overflow-y: auto;
    border: 1px solid gray;
    background-color: #fff;
}

.bill_table_section::-webkit-scrollbar {
    width: 0px;
}

.bill_table_section .table_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border-top: 1px solid grey; */
    padding: 5px 20px;
    /* margin: 10px 0; */
}

.table_heading .shop_name {
    text-transform: uppercase;
}

.invoice_detail_table {
    width: 100%;
    display: flex;
    align-items: center;
    /* border-top: 1px solid grey; */
    border-bottom: 1px solid grey;
}

.invoice_detail_table .invoice_table_item {
    width: calc(100% / 3);
    height: 50px;
    border-top: 1px solid grey;
}

.invoice_detail_table .invoice_table_item:nth-child(2) {
    border-top: none;
}

.invoice_detail_table .invoice_table_item:nth-child(2) {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    display: grid;
    place-items: center;
}

.invoice_table_item .row {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid grey;
    height: calc(100% / 2);
}

.invoice_table_item .row .row_text {
    width: calc(100% / 2);
}

.invoice_table_item .row h6 {
    padding: 5px 3px 5px 10px;

}

.invoice_table_item .row .row_text:last-child {
    border-left: 1px solid gray;
}

.invoice_table_item .row:last-child {
    border-bottom: none;
}

.bill_table_section .cutomer_detail {
    width: 100%;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 7px 0;
    padding: 0 10px;
}

.cutomer_detail .customer_detail_row {
    display: flex;
    align-items: center;
    margin: 1px 0;
}

.cutomer_detail .customer_detail_row .title {
    width: 20%;
    position: relative;
}

.cutomer_detail .customer_detail_row .title::before,
.cutomer_detail .customer_detail_row .title::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 2px;
    background-color: grey;
    top: 3px;
    right: 30px;
}

.cutomer_detail .customer_detail_row .title::after {
    top: 8px;
}

.bill_table_section .item_detail_table {
    width: 100%;
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
    margin: 7px 0;
    min-height: 40vh;
}

.item_detail_table .item_table_header,
.item_detail_table .item_table_data {
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    border-bottom: 1px solid grey;
}

.item_detail_table .item_table_header .item:last-child,
.item_detail_table .item_table_data .item:last-child {
    border-right: none;
}

.item_detail_table .item_table_header .item {
    width: calc(100% / 12);
    text-align: center;
    border-right: 1px solid grey;
    min-height: 40px;
    display: grid;
    place-items: center;
}

.item_detail_table .item_table_data .item {
    width: calc(100% / 12);
    text-align: center;
    border-right: 1px solid grey;
    display: grid;
    place-items: center;
    overflow-wrap: break-word;
}

.item_detail_table .cgst_wrapper,
.item_detail_table .sgst_wrapper {
    width: 13% !important;
}

/* .item_detail_table .item_table_header .item h6:nth-child(2) {
    border-top: 1px solid grey;
} */

.item_detail_table .item_table_header .item h6,
.item_detail_table .item_table_data .item h6 {
    padding: 5px 1px;
}

.item_detail_table .item_table_header .item .item_child {
    width: 100%;
}

.item_detail_table .item_table_header .item .item_child:nth-child(2) {
    border-top: 1px solid grey;
}

.item_table_header .particular,
.item_table_data .particular {
    width: 16% !important;
    display: flex !important;
    flex-direction: column !important;
    border-right: 1px solid grey;
    overflow-wrap: break-word;
}

.item_table_header .particular h6,
.item_table_data .particular h6 {
    padding-left: 8px;
}

.particular_child_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    text-align: right;
}

.particular_child_wrapper .purity,
.particular_child_wrapper .pieces {
    width: 50%;
    padding-right: 5px;
}

.particular_child_wrapper .purity h6,
.particular_child_wrapper .pieces h6 {
    padding-left: 0;
}


.cgst_child_wrapper,
.sgst_child_wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}

.cgst_child_wrapper .percentage,
.sgst_child_wrapper .percentage {
    width: 40%;
    border-right: 1px solid grey;
}

.cgst_child_wrapper .amount,
.sgst_child_wrapper .amount {
    width: 60%;
}

.bill_amount_table {
    display: flex;
    /* align-items: center; */
}

.bill_amount_table .bill_narration {
    width: 70%;
    min-height: 34vh;
    border: 1px solid grey;
    margin-right: 2px;
    border-left: none;
    position: relative;
}

.bill_amount_table .bill_narration .detail {
    width: 100%;
    padding: 10px 5px;
}

.bill_amount_table .bill_narration .detail p {
    font-size: 12px;
    margin-bottom: 10px;
}

.bill_amount_table .bill_narration .detail:nth-child(2) {
    border-top: 1px solid grey;
    border-bottom: 1px solid grey;
}

.bill_narration .bill_naration_detail {
    margin-left: 9%;
}

.bill_narration .salesman {
    position: absolute;
    bottom: 3px;
    left: 5px;
}

.bill_amount_table .bill_amount {
    width: 30%;
    min-height: 34vh;
    border: 1px solid grey;
    padding: 5px 10px;
}

.bill_amount_table .bill_amount .amount {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
    padding: 3px 0;
}

.bill_amount_table .bill_amount .amount:last-child {
    border-top: 1px solid grey;
}

.bill_table_section .authorization {
    width: 100%;
    display: flex;
}

.authorization .customer_section,
.authorization .authorization_section {
    width: calc(100% / 2);
    border-right: 1px solid grey;
    border-bottom: 1px solid grey;
}

.customer_section .detail,
.authorization_section .detail {
    padding: 3px 10px;
    min-height: 10vh;
}

.customer_section .detail p {
    font-size: 12px;
    margin-bottom: 10px;
}

.customer_section .detail:nth-child(1),
.authorization_section .detail:nth-child(1) {
    min-height: 4vh;
    border-bottom: 1px solid grey;
}

.customer_section .detail:nth-child(2),
.authorization_section .detail:nth-child(2) {
    border-bottom: 1px solid grey;
}

.customer_section .detail:last-child h6,
.authorization_section .detail:last-child h6 {
    text-align: center;
}

.authorization .detail {
    position: relative;
}

.authorization .detail span {
    position: absolute;
    font-size: 13px;
    font-weight: 600;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/*-----CSS for Update Account-----*/
.update_account_section {
    width: 100vw;
    height: 100vh;
    display: grid;
    place-items: center;
}

.update_account_section .view_loader {
    height: 50vh;
}

.update_account_form_wrapper {
    width: 80%;
    height: auto;
    margin: auto;
    border-radius: 15px;
    box-shadow: var(--outer_box_shadow);
    padding: 2% 5%;
    background-color: var(--secondary_bg_color);
}

.update_account_form_wrapper .title {
    text-align: center;
    margin-bottom: 25px;
}

.update_account_form_wrapper .input_row {
    width: 100%;
    margin-bottom: 25px;
}

.update_account_form_wrapper .input_row .input_box {
    width: calc(100% / 3);
    margin: 0 10px;
}

.update_account_form_wrapper .input_row .input_box input {
    width: 100%;
}

.update_account_form_wrapper .button_group {
    text-align: center;
}

.update_account_form_wrapper .button_group button {
    margin: 0 10px;
}

.original_copy {
    display: none;
}

/*-----css for Pagination----*/
.pagination {
    display: flex;
    list-style: none;
    outline: none;
    border-radius: 5px;
    border: 1px solid #0FF5CE;
    position: absolute;
    top: 0;
    right: 2%;
    transform: translate(10px, 10px);
}

.pagination li {
    padding: 0 8px;
    cursor: pointer;
    border: 1px solid #0FF5CE;
}

.pagination li a {
    text-transform: uppercase;
    font-size: 0.67em;
    font-weight: 600;
}

.pagination .active {
    background-color: #0FF5CE;
    color: #fff;
}

/************Client Dashboard End************/

@media print {
    .bill_table_section .item_detail_table {
        min-height: 30vh !important;
    }

    .original_copy {
        display: block;
    }

    .bill_table_section {
        width: 90% !important;
        height: 100%;
        overflow: visible;
        border: none;
    }

    .bill_table_section .letter_head {
        height: 18vh;
    }

    .bill_table_section .footer {
        height: 3vh;
    }

    .bill_table_section .table_main_wrapper {
        min-height: 80vh;
    }

    .bill_table_section .item_detail_table {
        min-height: 15vh;
    }

    .bill_amount_table .bill_narration {
        min-height: auto;
    }

    .bill_narration .amount_in_words {
        font-weight: normal;
    }

    .bill_amount_table .bill_amount {
        min-height: auto;
    }

    .customer_section .detail:nth-child(1),
    .authorization_section .detail:nth-child(1) {
        min-height: 2vh;
    }

    .customer_section .detail,
    .authorization_section .detail {
        min-height: 6.5vh;
    }

    .item_detail_table .item_table_header .item h6,
    .item_detail_table .item_table_data .item h6 {
        padding: 0px 2px;
        font-size: 8px;
        font-weight: normal;
    }

    .item_table_header .particular h6,
    .item_table_data .particular h6 {
        font-size: 8px;
        font-weight: normal;
    }

    .bill_table_section .cutomer_detail .data h6 {
        font-weight: 500;
    }

    .bill_amount_table .bill_amount .amount {
        padding: 0;
        margin: 0;
    }

    .bill_amount_table .bill_narration .detail {
        padding: 3px 5px;
    }

    .bill_amount_table .bill_amount h6 {
        font-weight: normal;
        font-size: 8px;
    }

    .bill_amount_table .bill_amount .net_payable h6 {
        font-weight: bold;
    }

    .invoice_detail_table .invoice_table_item:nth-child(1) {
        border-left: 1px solid grey;
    }

    .invoice_detail_table .invoice_table_item:nth-child(3) {
        border-right: 1px solid grey;
    }

    .bill_table_section .cutomer_detail {
        border: 1px solid grey;
    }

    .bill_table_section .item_detail_table {
        border-left: 1px solid grey;
        border-right: 1px solid grey;
    }

    .bill_amount_table .bill_narration {
        border-left: 1px solid grey;
    }

    .bill_table_section .authorization {
        border-left: 1px solid grey;
    }
}