@media only screen and (min-width:768px) and (max-width:1024px) {
    .sign_in_form_main {
        width: 85%;
    }
}

@media only screen and (min-width:320px) and (max-width:767px) {
    .sign_in_form_main {
        width: 100%;
        flex-direction: column;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
    }

    .sign_in_form_main .company_details,
    .sign_in_form_main .sign_in_form {
        width: 100%;
    }
    .sign_in_form_main .company_details {
        margin-bottom: 1rem;
    }
    .sign_in_form_main .company_details h1 {
        font-size: 24px;
    }
    .sign_in_form .input_field input {
        padding: 0.7rem;
    }
}